<template>
  <!-- prettier-ignore -->
  <div
    class="vital-preview"
    :class="{ [`vital-preview--${$props.theme}`]: true }"
    :app-language="locale"
    :app-name="appName$"
    :app-version="version$"
    >
    <widget-loading v-if="isLoading || !isReadyWithPartner"/>
    <div
      v-else
      class="vital-preview__wrapper"
    >
      <div class="vital-preview__content">
        <template v-if="!price">
          <the-typography
            class="vital-preview__title"
            tag="div"
            type="heading02"
            v-text="$props.titleLabel"
        />
          <entry-form
            v-if="!price"
            v-model="formData"
            :button-text="$t('vitalPreview.button')"
            :channel="CHANNEL.VITALPREVIEWWIDGET"
            :theme="$props.theme"
            @submit="calculatePrice"
          />
        </template>
        <div
          v-else
          class="vital-preview__product"
        >
          <the-typography
            tag="span"
            type="heading02"
            v-text="$t(`content.products.${$props.productId}.name`)"
          />
          <the-typography type="heading01">
            <product-price
              :price="price"
              :suffix="$t('product.currency')"
            />
          </the-typography>
          <div class="product__description">
            <the-typography
              tag="span"
              type="bodyMediumLong"
              v-text="$t(`content.products.${$props.productId}.name`)"
            />
            <the-typography
              tag="span"
              type="bodyMediumLong"
              v-text="options"
            />
          </div>
          <div class="vital-preview__actions">
            <Form.Button
              plain
              :text="$t('vitalPreview.restart')"
              @click="restart"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { inject, ref } from 'vue'

import productAPI from '@/api/product'
import useApplication from '@/hooks/useApplication'
import useI18n from '@/hooks/useI18n'
import useProduct from '@/hooks/useProduct'
import useToast from '@/hooks/useToast'

import * as Form from '@/components/Form'
import EntryForm from '@/modules/EntryWidget/components/EntryForm'
import ProductPrice from '@/components/Product/ProductPrice'
import WidgetLoading from '@/components/Container/WidgetLoading'

import { CATEGORY_TYPE, CHANNEL, DEFAULT_MASTER_TREATY, NOTIFICATION } from '@/config/constants'

// HOOKS
const { isReadyWithPartner } = useApplication()
const { locale, t } = useI18n()
const { composeOptions } = useProduct()
const { addToast } = useToast()

// INIT
const appName$ = inject('appName$')
const version$ = inject('version$')
const props = defineProps({
  criteriaAm: {
    type: String,
    default: '',
  },
  criteriaGf: {
    type: String,
    default: '',
  },
  productId: {
    type: String,
    required: true,
    validator(value) {
      return ['SAMB06SProdukt', 'SAMB06MProdukt', 'SAMB06LProdukt'].includes(value)
    },
  },
  theme: {
    type: String,
    default: 'light',
  },
  titleLabel: {
    type: String,
    default: '',
  },
})

// DATA
const formData = {}
const isLoading = ref(false)
const options = ref('')
const price = ref('')

// METHODS
async function calculatePrice(value) {
  try {
    isLoading.value = true
    const vitalContractStartDate = '2024-01-01'
    const payload = {
      address: { ...value.location },
      contractStartDate: vitalContractStartDate,
      dateOfBirth: value.dateOfBirth,
      gender: value.gender,
      masterTreaty: DEFAULT_MASTER_TREATY,
      selectedPriceCombination: {
        duration: '1',
        ...(props.criteriaAm && { amValue: parseInt(props.criteriaAm, 10) }),
        ...(props.criteriaGf && { gfValue: parseInt(props.criteriaGf, 10) }),
        selected: true,
      },
    }
    const productPrices = await productAPI.calculateAllPricesForProduct(props.productId, payload)
    price.value = productPrices.find(p => p.selected).price
    options.value = composeOptions(
      { prices: productPrices, productId: props.productId },
      'ADDITIONAL_CLASSIC',
      CATEGORY_TYPE.ADDITIONAL
    )
    isLoading.value = false
  } catch {
    isLoading.value = false
    addToast({
      text: t('error.GENERAL'),
      type: NOTIFICATION.ERROR,
    })
  }
}

function restart() {
  price.value = ''
  options.value = ''
}
</script>

<style name="variables">
.vital-preview {
  --sm-body-padding: 8px;
  --md-body-padding: 20px;
  --lg-body-padding: 32px;
}
</style>

<style name="mobile" scoped>
.vital-preview {
  position: relative;
  padding: 20px 0;
  margin-left: calc(-1 * var(--sm-body-padding));
  margin-right: calc(-1 * var(--sm-body-padding));
}

.vital-preview--light {
  color: var(--c-primary-neutral-1);
  background-color: var(--c-primary-neutral-3);
}

.vital-preview--dark {
  background-color: var(--c-primary-color-1);
  color: var(--c-primary-neutral-3);
}

.vital-preview__wrapper {
  margin: 0 16px;
}

.vital-preview__title {
  margin: 16px 0 12px 0;
}

.vital-preview__product {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.product__description {
  display: flex;
  flex-direction: column;
}

.vital-preview__actions {
  display: flex;

  :deep(.button) {
    color: var(--c-primary-color-3);
  }
}
</style>

<style name="desktop" scoped>
@media (--md) {
  .product__description {
    flex-direction: row;
    gap: 8px;
  }

  .vital-preview__actions {
    justify-content: flex-end;
  }
}
</style>

<style name="desktop-com-handling" scoped>
/* Special handling for desktop on .com */
/* you need 1000px for a clean cut between full form size */
@media (min-width: 1000px) {
  .vital-preview {
    padding: 35px 0 20px;
  }

  .vital-preview__wrapper {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
  }

  .vital-preview__content {
    max-width: 940px;
    margin: 0 30px;
  }

  .vital-preview__title {
    margin-top: 0;
    margin-bottom: 28px;
  }
}
</style>
