import { registerContinuously } from '@/helpers/Widget'
import EntryWidget from '@/modules/EntryWidget/components/EntryWidget'

registerContinuously(EntryWidget, '.sanitasOvpEntry', props => {
  //const props = element.dataset

  // Transform props string-booleans to boolean (for TRUE we need an empty string)
  if (props.header === 'true' || props.header === '') {
    props.header = ''
  } else {
    delete props.header
  }
  if (props.tiny === 'true' || props.tiny === '') {
    props.tiny = ''
  } else {
    delete props.tiny
  }

  // Fallback for old AEM implementation
  if (props.whiteBackground === 'true' || props.whiteBackground === '') {
    props.theme = 'light'
  }

  return props
})
