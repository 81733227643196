<template>
  <!-- prettier-ignore -->
  <Form.Form
    class="entryForm"
    name="entry_widget"
    tracking-disabled
    :validator="v"
    @cancel="$emit('navigate')"
    @submit="submit"
  >
    <Form.Location
      v-model="formData.location"
      class="entryForm-location"
      name="entry.location"
      required
      :contract-start-date="$props.contractStartDate"
      :hint="$t('location.placeholder')"
      :readonly="preFilled"
      :theme="$props.theme === 'dark' ? 'light' : 'dark'"
      :title-label="$t('person.plz')"
      :validate="v.location"
    />
    <Form.DateInput
      v-model="formData.dateOfBirth"
      class="entryForm-dateOfBirth"
      name="entry.dateOfBirth"
      :placeholder="$t('form.dateHint')"
      :readonly="preFilled"
      :theme="$props.theme === 'dark' ? 'light' : 'dark'"
      :title-label="birthdateText"
      :validate="v.dateOfBirth"
    />
    <Form.Gender
      v-model="formData.gender"
      class="entryForm-gender"
      forced-dropdown
      :age="age"
      :disabled="preFilled"
      :readonly="preFilled"
      :validate="v.gender"
    />
    <Form.Button
      v-if="$props.showRestart"
      class="entryForm-restart"
      data-track-as="restart"
      plain
      :data-track-text="$props.channel"
      @click="restart"
    >
      <the-icon
        v-if="!isDesktop"
        art="light"
        name="arrow-rotate-left"
      />
      <span
        class="restart-text"
        v-text="$t('entry.restart')"
      />
    </Form.Button>
    <Form.Button
      class="entryForm-submit-button"
      primary
      type="submit"
      :text="$props.buttonText ? $props.buttonText : $t('entry.button')"
    />
  </Form.Form>
</template>

<script setup>
import { computed, ref, reactive, watchEffect } from 'vue'

import useVuelidate from '@vuelidate/core'

import validations from '@/validations/entryWidget'
import { DateTimeHelper } from '@/helpers'

import useAuth from '@/hooks/useAuth'
import useBrowser from '@/hooks/useBrowser'
import useI18n from '@/hooks/useI18n'

import * as Form from '@/components/Form'

import { GENDER } from '@/config/constants'

// HOOKS
const { restartApplication } = useAuth()
const { browser } = useBrowser()
const { t } = useI18n()

// INIT
const emit = defineEmits(['submit', 'navigate'])
const props = defineProps({
  buttonText: {
    type: String,
    default: '',
  },
  channel: {
    type: String,
    required: true,
  },
  contractStartDate: {
    type: String,
    default: '2021-01-01',
  },
  modelValue: {
    type: Object,
    default: () => {},
  },
  showRestart: {
    type: Boolean,
    default: false,
  },
  theme: {
    type: String,
    default: 'dark',
  },
})

// DATA
const formData = reactive({
  dateOfBirth: null,
  gender: null,
  location: {},
  personId: null,
})
const preFilled = ref(false)

// COMPUTED
const age = computed(() => {
  return DateTimeHelper.getAge(formData.dateOfBirth)
})

const birthdateText = computed(() => {
  return formData.gender === GENDER.UNBORN ? t('person.estimatedBirthdateShort') : t('person.birthdate')
})

const isDesktop = computed(() => {
  return browser.isDesktop
})

// METHODS
function restart() {
  return restartApplication()
}

function submit() {
  emit('submit', formData)
}

// VALIDATION
const v = useVuelidate(validations, formData, { $lazy: true })

// WATCHERS
watchEffect(() => {
  preFilled.value = false

  if (Object.keys(props.modelValue).length === 4) {
    preFilled.value = true
  }

  Object.assign(formData, {
    dateOfBirth: props.modelValue.dateOfBirth ?? null,
    gender: props.modelValue.gender ?? null,
    location: props.modelValue.location ?? {},
    personId: props.modelValue.personId,
  })
})
</script>

<style name="mobile" scoped>
.entryForm {
  display: grid;
  grid-gap: 20px;
  grid-template-areas:
    'location location'
    'dateOfBirth dateOfBirth'
    'gender gender'
    'button button'
    'restart restart';
  grid-template-columns: 94% 1fr;

  &-location {
    grid-area: location;
  }

  &-dateOfBirth {
    grid-area: dateOfBirth;
  }

  &-gender {
    grid-area: gender;
  }

  &-restart {
    grid-area: restart;

    & :deep(.icon) {
      margin: 0px 8px;
    }

    .restart-text {
      color: var(--c-primary-color-3);
      margin: auto;

      &:hover {
        color: var(--c-primary-color-2);
      }
    }
  }

  &-submit-button {
    grid-area: button;
  }
}
</style>

<style name="desktop" scoped>
@media (--md) {
  .entryForm {
    grid-template-areas: 'location dateOfBirth gender restart button';
    grid-template-columns: 280px 160px 170px 1fr;

    &-gender {
      margin: 0 20px 0 0;
    }

    &-restart {
      .restart-text {
        width: 96px;
        height: 48px;
        line-height: 24px;
        display: flex;
        justify-content: center;
        align-content: center;
        flex-direction: column;
        margin: inherit;
      }
    }
  }
}
</style>
