<template>
  <!-- prettier-ignore -->
  <div class="ovp-benefits-widget-wrapper">
    <benefits-table
      v-if="Object.keys(benefitsTableData).length && isReadyWithPartner"
      class="ovp-benefits-widget"
      :benefits-data="benefitsTableData"
      :app-version="version$"
      :app-name="appName$"
      :app-language="selectedLanguage"
      :selected-id="selectedId"
    />
  </div>
</template>

<script setup>
import { onMounted, reactive, ref, inject } from 'vue'

import resourcesAPI from '@/api/resources'
import useApplication from '@/hooks/useApplication'
import useBenefits from '@/hooks/useBenefits'
import useI18n from '@/hooks/useI18n'

import BenefitsTable from '@/components/Benefits/BenefitsTable'

// HOOKS
const { isReadyWithPartner } = useApplication()
const { getBenefitsTableData } = useBenefits()
const { selectedLanguage } = useI18n()

// INIT
const appName$ = inject('appName$')
const version$ = inject('version$')
// @TODO try to remove url-parse dependency and use URLSearchParams API
const parse = location => import('url-parse').then(parser => parser.default(location.href, true))
const props = defineProps({
  benefitColumns: {
    type: String,
    required: true,
  },
  benefitPath: {
    type: String,
    required: true,
  },
  benefitSelectedId: {
    type: String,
    default: null,
  },
  benefitShowProductLinks: {
    type: String,
    default: 'false',
  },
})

// DATA
const benefits = reactive({})
const benefitsTableData = reactive({})
const columns = reactive(props.benefitColumns.split(','))
const path = ref(props.benefitPath)
const selectedId = ref(props.benefitSelectedId)

// METHODS
async function initalLocationParams() {
  const location = await parse(window.location)
  const columnsQuery = location.query?.columns
  const selectedIdQuery = location.query?.selectedId
  const pathQuery = location.query?.path

  const hasAllParams = columnsQuery && selectedIdQuery && pathQuery

  if (Object.keys(location?.query).length > 0 && hasAllParams) {
    Object.assign(columns, columnsQuery?.split(','))
    selectedId.value = selectedIdQuery

    const tmp = props.benefitPath.split('/')
    tmp[tmp.length - 1] = pathQuery
    path.value = tmp.join('/')
  }
}

function loadContent() {
  resourcesAPI.getContentByPath(path.value).then(response => {
    Object.assign(benefits, response)
    Object.assign(
      benefitsTableData,
      getBenefitsTableData(response, columns, selectedId.value, props.benefitShowProductLinks === 'true')
    )
  })
}

// LIFECYCLE HOOKS
onMounted(() => {
  // @TODO check whether this is still needed
  if (window.location?.search.indexOf('selectedId')) {
    initalLocationParams()
  }

  loadContent()
})
</script>

<style name="mobile" scoped>
.ovp-benefits-widget {
  margin-bottom: 10px;

  &-wrapper {
    min-height: 100px;
  }
}
</style>

<style name="desktop" scoped>
@media (--md) {
  .ovp-benefits-widget {
    margin-bottom: 0;
  }
}
</style>
