<template>
  <!-- prettier-ignore -->
  <transition
    appear
    name="fade"
  >
    <div class="loading">
      <the-icon
        class="fa-spin loading__icon"
        art="light"
        name="spinner-third"
      />
    </div>
  </transition>
</template>

<style name="transitions" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

<style name="mobile" scoped>
.loading {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: center;
  align-items: center;

  &__icon {
    color: var(--c-primary-neutral-3);
    font-size: 25px;
    margin: 0 10px 0 0;
  }

  &__text {
    color: var(--c-primary-neutral-3);
    font-size: 25px;
    line-height: 30px;
  }
}
</style>
