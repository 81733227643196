<template>
  <!-- prettier-ignore -->
  <div
    class="tabs"
    :class="{
      'tabs--empty': $props.persons.length === 0,
      'tabs--exceeding': isTabsExceeding
    }"
  >
    <div class="tabs__header">
      <div
        v-if="activePerson"
        class="tabs-person"
        @click="toggle"
      >
        <product-widget-person-tab
          selected
          :person="activePerson"
        />
        <the-icon
          art="light"
          name="angle-down"
          :flip="toggled ? 'vertical' : 'horizontal'"
        />
      </div>
      <div
        v-if="isTabsExceeding"
        class="tabs__actions--exceeding"
      >
        <the-icon
          art="light"
          name="pencil"
          @click="$emit('open')"
        />
        <the-icon
          v-if="showRestart"
          v-tooltip="$t('screen.priceWidget.widget.restart')"
          art="light"
          data-track-as="restart"
          name="arrow-rotate-left"
          :data-track-text="CHANNEL.PRODUCTWIDGET"
          @click="restart"
        />
      </div>
    </div>
    <template v-if="$props.persons.length > 0">
      <transition name="tabs">
        <div
          v-if="toggled || showTabs"
          class="tabs-list"
          :style="{ gridTemplateColumns: `repeat(${$props.persons.length}, minmax(45px, 210px)) 1fr` }"
        >
          <template
            v-for="person in $props.persons"
            :key="person.personId"
          >
            <product-widget-person-tab
              :person="person"
              :selected="person.personId === $props.personId"
              @select="selectPerson"
            />
          </template>
          <template v-if="!browser.isDesktop">
            <div class="tabs__actions--mobile">
              <div
                v-if="showRestart"
                class="actions__tab"
                @click="restart"
              >
                <span v-text="$t('screen.priceWidget.widget.restart')" />
                <the-icon
                  art="regular"
                  name="arrow-rotate-left"
                />
              </div>
              <div
                v-if="!isExistingCustomer"
                class="actions__tab"
                @click="openMobileEdit"
              >
                <span v-text="$t('person.editPersons')" />
                <the-icon
                  art="regular"
                  name="pencil"
                />
              </div>
            </div>
          </template>
          <template v-else>
            <div class="tabs__actions--desktop">
              <the-icon
                v-if="!isExistingCustomer"
                art="light"
                name="pencil"
                @click="$emit('open')"
              />
              <span
                v-if="showRestart"
                v-tooltip="$t('screen.priceWidget.widget.restart')"
              >
                <the-icon
                  art="light"
                  name="arrow-rotate-left"
                  @click="restart"
                />
              </span>
            </div>
          </template>
        </div>
      </transition>
    </template>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'

import basketStore from '@/store/basket'
import useAuth from '@/hooks/useAuth'
import useBrowser from '@/hooks/useBrowser'

import ProductWidgetPersonTab from '@/modules/ProductWidget/components/Tab'

import { CHANNEL, PRICE_WIDGET_MAX_TABS_SIZE } from '@/config/constants'

// HOOKS
const { restartApplication } = useAuth()
const { browser } = useBrowser()

// INIT
const emit = defineEmits(['open', 'select'])
const props = defineProps({
  personId: {
    type: String,
    default: '',
  },
  persons: {
    type: Array,
    required: true,
  },
})

// DATA
const toggled = ref(false)

// COMPUTED
const activePerson = computed(() => {
  return props.persons.find(p => p.personId === props.personId)
})

const isExistingCustomer = computed(() => {
  // @TODO describe exactly what this magic computed does in a comment
  return basketStore.basket.existingCustomer && !basketStore.basket.persons.some(p => !p.partnerNumber)
})

const isTabsExceeding = computed(() => {
  return browser.isDesktop && props.persons.length > PRICE_WIDGET_MAX_TABS_SIZE
})

const showRestart = computed(() => {
  return !basketStore.isTemporaryBasket()
})

const showTabs = computed(() => {
  return browser.isDesktop && props.persons.length <= PRICE_WIDGET_MAX_TABS_SIZE
})

// METHODS
function openMobileEdit() {
  emit('open')
  toggle()
}

function restart() {
  restartApplication()
}

function selectPerson(personId) {
  toggle()
  emit('select', personId)
}

function toggle() {
  toggled.value = !toggled.value
}
</script>

<style name="animations" scoped>
.tabs {
  &-enter,
  &-leave-to {
    transform: scaleY(0);
  }

  &-enter-active,
  &-leave-active {
    transition: transform 0.3s ease-in-out;
    transform-origin: top;
  }
}
</style>

<style name="mobile" scoped>
.tabs__actions--exceeding {
  display: flex;
  align-items: center;
  gap: 16px;

  & :deep(.icon) {
    cursor: pointer;
    color: var(--c-primary-neutral-1);
  }

  & :deep(.icon:hover) {
    color: var(--c-primary-color-2);
  }
}

.tabs__actions--mobile {
  .actions__tab {
    color: var(--c-primary-color-3);
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 15px;
    line-height: 20px;
    height: 50px;
    border-bottom: 1px solid var(--c-secondary-neutral-3);
  }
}

.tabs__actions--desktop {
  padding-left: 16px;
  display: flex;
  gap: 16px;
  align-items: center;

  & :deep(.icon) {
    cursor: pointer;
    color: var(--c-primary-neutral-1);
  }

  & :deep(.icon:hover) {
    color: var(--c-primary-color-2);
  }
}

.tabs--exceeding .tabs__actions--desktop {
  display: none;
}

.tabs {
  display: flex;
  flex-direction: column;
  user-select: none;
  position: relative;
  max-width: 100%;

  &--empty {
    background-color: var(--c-primary-neutral-3);
  }

  &-person {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    position: relative;
    padding-right: 16px;
    background-color: var(--c-primary-neutral-3);
    border-bottom: 1px solid var(--c-secondary-neutral-3);

    .icon {
      font-size: 30px;
    }
  }

  &-list {
    display: flex;
    flex-direction: column;
    z-index: 3;
    position: absolute;
    top: 50px;
    width: 100%;
    box-shadow:
      0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 5px 0 rgba(0, 0, 0, 0.12),
      0 3px 1px -2px rgba(0, 0, 0, 0.2);
    background-color: var(--c-primary-neutral-3);

    & :deep(.tab) {
      max-width: initial;
      border-bottom: 1px solid var(--c-secondary-neutral-3);

      &:first-child {
        border-top: 1px solid var(--c-secondary-neutral-3);
      }
    }
  }

  &-empty {
    padding: 10px;

    div:first-child {
      font-weight: bold;
      font-size: 15px;
      line-height: 20px;
    }

    .button {
      margin: 10px 0 0 0;
      font-weight: bold;
    }
  }
}

.tabs__header {
  display: flex;
  gap: 16px;
  align-items: center;
}
</style>

<style name="desktop" scoped>
@media (--md) {
  .tabs {
    &--empty {
      grid-template-columns: 1fr 250px;
      grid-template-rows: 50px;
      font-size: 15px;
      padding: 15px;

      color: var(--c-primary-neutral-1);

      font-weight: bold;

      div:first-child {
        padding: 10px;
      }
    }

    &-empty {
      padding: 0;

      div:first-child {
        font-weight: bold;
        font-size: 15px;
        line-height: 20px;
      }

      .button {
        margin: initial;
      }
    }
  }

  .tabs:not(.tabs--exceeding) {
    .tabs-person {
      display: none;
    }

    .tabs-list {
      position: initial;
      box-shadow: none;
      display: grid;
      grid-gap: 4px;
      grid-template-rows: 40px;
      background-color: transparent;

      & :deep(.tab) {
        max-width: initial;
        border: none;

        &:first-child {
          border: none;
        }
      }
    }
  }

  .tabs--exceeding .tabs-person {
    flex: 0;
    min-width: 250px;
    border-bottom: none;
  }

  .tabs--exceeding .tabs-list {
    width: 250px;
  }
}
</style>
