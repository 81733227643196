<template>
  <!-- prettier-ignore -->
  <div
    ref="productWidgetRef"
    class="product-widget-wrapper"
    :app-language="locale"
    :app-name="appName$"
    :app-version="version$"
  >
    <!-- LOADING STATE -->
    <template v-if="!isReadyWithPartner">
      <div class="widget__loading">
        <the-icon
          class="fa-spin loading__icon"
          art="light"
          name="spinner-third"
        />
      </div>
    </template>
    <!-- ONLY ONE PRODUCT; NO PEOPLE => show ContractForm -->
    <template v-else-if="productGroup.length === 1 && displayPersons.mutable.length === 0">
      <the-screen v-if="basketStore.basket.contractStartDate">
        <screen-contract
          ref="contract"
          theme="dark"
          :channel="CHANNEL.PRODUCTWIDGET"
          :product-id="$props.productId"
          :source="$props.source"
          @cancel="close"
          @submit="submit"
        />
      </the-screen>
    </template>
    <!-- WHATEVER => show Products -->
    <template v-else>
      <template v-if="displayPersons.mutable.length === 0">
        <template v-if="isLoginEnabled">
          <login-action
            ref="loginRef"
            class="pwLogin"
            :channel="CHANNEL.PRODUCTWIDGET"
            :product-id="$props.productId"
          />
        </template>
      </template>
      <template v-else>
        <product-person-tabs
          :person-id="personId"
          :persons="displayPersons.mutable"
          @open="open"
          @select="selectPerson"
        />
      </template>
      <div class="carousel">
        <div
          ref="cardsRef"
          class="carousel__content"
        >
          <div
            class="cards"
            :class="classes"
            :style="`--dvp-product-length: ${productGroup.length}`"
          >
            <product-card
              v-for="product in productGroup"
              :key="product.productId"
              class="card"
              :mode="productGroup.length > 1 ? 'multi' : 'single'"
              :person-id="personId"
              :product-id="product.productId"
              :product="product"
              :source="$props.source"
              :with-options="hasOptions"
              @open="open"
            />
          </div>
        </div>
        <template v-if="hasCarousel">
          <div class="carousel__controls">
            <the-icon
              v-for="(_product, index) in productGroup"
              :key="index"
              name="circle"
              :art="selectedProductIndex === index ? 'solid' : 'light'"
              @click="goto(index)"
            />
          </div>
        </template>
      </div>
      <the-modal
        v-model="isContractOpen"
        x-only
        :themes="['mobile--dark']"
      >
        <the-screen>
          <screen-contract
            ref="contract"
            theme="dark"
            :channel="CHANNEL.PRODUCTWIDGET"
            :product-id="$props.productId"
            :source="$props.source"
            @cancel="close"
            @submit="submit"
          />
        </the-screen>
      </the-modal>
      <the-form-modal
        v-slot="{ modalProps }"
        v-model="isProductSuggestionOpen"
        locked
      >
        <product-suggestion
          :category-id="suggestion.categoryId"
          :doctor="suggestion.doctor"
          :group-id="suggestion.groupId"
          :person-id="suggestion.personId"
          :product="suggestion.product"
          :source="suggestion.source"
          @added="modalProps.close"
          @close="showProductSuggestion = false"
        />
      </the-form-modal>
    </template>
  </div>
</template>

<script setup>
import { computed, inject, onMounted, nextTick, ref, watch } from 'vue'

import debounce from 'lodash/debounce'
import isEqual from 'lodash/isEqual'

import { events$, loading$ } from '@/services'

import basketStore from '@/store/basket'
import partnerStore from '@/store/partner'
import productStructureStore from '@/store/productStructure'
import useApplication from '@/hooks/useApplication'
import useBrowser from '@/hooks/useBrowser'
import useI18n from '@/hooks/useI18n'
import usePersonDetails from '@/hooks/usePersonDetails'
import usePersonSpecificProduct from '@/hooks/usePersonSpecificProduct'
import useProduct from '@/hooks/useProduct'

import ProductCard from '@/modules/ProductWidget/components/ProductCard'
import ProductPersonTabs from '@/modules/ProductWidget/components/Tabs'
import ProductSuggestion from '@/components/Product/Replace/ProductSuggestion'
import ScreenContract from '@/components/Screen/ScreenContract'
import { LoginAction } from '@/components/Login'

import { CHANNEL } from '@/config/constants'
import { EVENT_LOGIN, EVENT_SHOW_PRODUCT_SUGGESTION, SOURCE } from '@/config/events'

// HOOKS
const { isReadyWithPartner } = useApplication()
const { browser } = useBrowser()
const { locale } = useI18n()
const { getPersonsByMutability } = usePersonDetails()
const { getProduct } = usePersonSpecificProduct()
const { hasOptions: __hasOptions } = useProduct()

// INIT
const appName$ = inject('appName$')
const version$ = inject('version$')
const props = defineProps({
  products: {
    type: String,
    required: true,
  },
  selected: {
    type: String,
    default: 'A00Produkt',
  },
  source: {
    type: String,
    default: SOURCE.PRODUCT_WIDGET,
  },
})

// DATA
const installed = ref(false)
const cardsRef = ref(null)
const personId = ref(null)
const isContractOpen = ref(false)
const isProductSuggestionOpen = ref(false)
const loginRef = ref(null)
const selectedProductIndex = ref(null)
const suggestion = ref({
  categoryId: null,
  doctor: null,
  groupId: null,
  personId: null,
  product: null,
  source: null,
})

// COMPUTED
const classes = computed(() => {
  return {
    'cards--single': productGroup.value.length === 1,
    'cards--duo': productGroup.value.length === 2,
    'cards--multi': productGroup.value.length > 2,
    'cards--optionless': !hasOptions.value,
  }
})

const displayPersons = computed(() => {
  return getPersonsByMutability()
})

const hasCarousel = computed(() => {
  if (browser.isDesktop) {
    return productGroup.value.length > 3
  } else {
    return productGroup.value.length > 1
  }
})

const hasOptions = computed(() => {
  // no person? no good!
  if (!personId.value) return false

  // no valid/price/selected/any options? no good!
  if (
    !productGroup.value.some(p => {
      return (p.valid || p.selected) && p.prices && __hasOptions(p)
    })
  )
    return false

  // all good!
  return true
})

const isLoginEnabled = computed(() => {
  return (
    (basketStore.basket.channel !== CHANNEL.ALVA &&
      !partnerStore.isPrivateInsurer.value &&
      !partnerStore.isBroker.value &&
      !partnerStore.isCollective.value &&
      !basketStore.basket.existingCustomer) ||
    !!loginRef.value?.isLoginModalOpen
  )
})

const productGroup = computed(() => {
  return props.products.split(',').map(_productId => {
    const productId = _productId.trim()

    return {
      productId,
      ...productStructureStore.products.value[productId],
      ...getProduct(personId.value, productId),
    }
  })
})

// METHODS
function __reduce(acc, current) {
  acc.push(current.personId)
  return acc
}

function close() {
  isContractOpen.value = false
}

function goto(index) {
  selectedProductIndex.value = index

  const el = cardsRef.value
  if (el) {
    el.scroll({
      top: 0,
      // formula: [pos of product] * ([width of one card] + [gap])
      left: index * (el.children[0].children[0].getBoundingClientRect().width + 20),
      behavior: 'smooth',
    })
  }
}

function handleScroll(element) {
  const raw = element.scrollLeft / element.offsetWidth
  const pos = Math.round(raw)

  goto(pos)
}

function open() {
  isContractOpen.value = true
}

function selectPerson(_personId) {
  const mutablePeople = displayPersons.value.mutable.reduce(__reduce, [])

  if (mutablePeople.length === 0) return

  if (!_personId) _personId = mutablePeople[0]

  return (personId.value = _personId)
}

async function submit(__basket) {
  loading$.start()

  if (!__basket.channel) __basket.channel = CHANNEL.PRODUCTWIDGET

  try {
    await basketStore.updateOvpBasket(__basket)

    await nextTick()

    close()

    loading$.end()
  } catch {
    loading$.failed()
  }
}

// WATCHERS
watch(
  () => displayPersons.value,
  (newPeople, oldPeople) => {
    const sets = {
      new: newPeople.mutable.reduce(__reduce, []),
      old: oldPeople.mutable.reduce(__reduce, []),
    }

    // no people yet, abort
    if (sets.new.length === 0) return

    // init => select first person
    if (!personId.value) {
      selectPerson()

      // a person has been removed
      // selected person has been removed => select first person
    } else if (!isEqual(sets.new, sets.old) && !sets.new.find(p => p === personId.value)) {
      selectPerson(sets.new[0])
    }
  }
)

watch(
  () => productGroup.value,
  _productGroup => {
    if (_productGroup.some(p => p.medicalOffice)) {
      events$?.on(EVENT_SHOW_PRODUCT_SUGGESTION, opts => {
        isProductSuggestionOpen.value = true
        suggestion.value = { ...opts }
      })
    }

    if (!installed.value && _productGroup.length > 0) {
      let _index = productGroup.value.findIndex(p => p.productId === props.selected)
      if (_index === -1) _index = 0

      goto(_index)
      installed.value = true
    }
  }
)

watch(
  () => browser.isDesktop,
  (newState, oldState) => {
    // when the viewport changes, reset the carousel
    if (newState !== oldState) {
      goto(0)
    }
  }
)

watch(
  () => cardsRef.value,
  element => {
    if (element) {
      element.addEventListener('touchend', () => debounce(handleScroll, 50)(element))
      element.addEventListener('scrollend', () => debounce(handleScroll, 50)(element))
    }
  }
)

// LIFECYCLE HOOKS
onMounted(() => {
  events$.on(EVENT_LOGIN.OPEN, () => {
    isContractOpen.value = false
  })
})
</script>

<style name="mobile" scoped>
.product-widget-wrapper {
  cursor: default;
  width: 100%;
  margin: 0 0 8px; /* note: hack, till dotcom changes their container... */

  .widget__loading {
    width: 100%;
    font-size: 40px;
    text-align: center;
    padding: 8px 16px;
  }

  .cards {
    display: grid;
    column-gap: 16px;

    grid-template-rows: min-content min-content min-content;
    grid-template-columns: repeat(var(--dvp-product-length), 100%);
    grid-auto-flow: column;

    &.cards--optionless {
      grid-template-rows: min-content min-content;
    }

    :deep(.button) {
      width: 100%;
      min-width: 200px;
    }

    :deep(.cardSection.cardSection__head) {
      border-bottom: 2px solid var(--c-primary-neutral-2);
    }
  }
}

.carousel {
  margin: 2px 0 0;

  &__content {
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__controls {
    display: flex;
    justify-content: center;
    gap: 8px;

    padding: 8px 0;

    .icon {
      cursor: pointer;
    }
  }
}

.pwLogin {
  margin: 0 0 16px 0;
}
</style>

<style name="desktop" scoped>
@media (--md) {
  .product-widget-wrapper {
    .cards {
      grid-template-columns: repeat(var(--dvp-product-length), minmax(310px, 1fr));
    }
  }

  .carousel {
    &__content {
      overflow: hidden;
    }
  }
}
</style>
