<template>
  <!-- prettier-ignore -->
  <div
    class="tab"
    :class="{ 'tab--active': $props.selected }"
    @click="$emit('select', $props.person.personId)"
  >
    <person-name
      class="tabs-tab__name"
      :person="$props.person"
    />
    <the-icon
      v-if="isLocked"
      art="light"
      name="lock-keyhole"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue'

import basketStore from '@/store/basket'

import PersonName from '@/components/Person/PersonName'

// INIT
defineEmits(['select'])
const props = defineProps({
  person: {
    type: Object,
    required: true,
  },
  selected: {
    type: Boolean,
    default: false,
  },
})

// COMPUTED
const isLocked = computed(() => {
  return basketStore.basket.existingCustomer && !props.person.contractModified
})
</script>

<style name="mobile" scoped>
.tab {
  min-width: 40px;
  max-width: 200px;
  cursor: pointer;
  padding: 15px;
  height: 50px;
  line-height: 20px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;

  background-color: var(--c-secondary-neutral-3);

  &--active {
    background-color: var(--c-primary-neutral-3);
  }

  &__name {
    width: 100%;
  }

  & .icon {
    color: inherit;
  }
}

.tabs-tab__name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>

<style name="desktop" scoped>
@media (--md) {
  .tab {
    height: initial;
    padding: 10px;
  }
}
</style>
