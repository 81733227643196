<template>
  <div
    v-bind="$attrs"
    class="cardSection cardSection__head"
  >
    <the-typography
      type="heading02"
      v-text="$t(`content.products.${$props.productId}.name`)"
    />
    <the-typography
      type="bodyLargeShort"
      v-html="$t(`content.products.${$props.productId}.description`)"
    />
    <div
      v-if="te(`content.products.${$props.productId}.altDescription`)"
      class="product__bullets"
    >
      <div
        v-for="(text, index) in $t(`content.products.${$props.productId}.altDescription`)"
        :key="index"
        class="bullet bullet--positive"
      >
        <the-icon
          art="light"
          name="check"
        />
        <div>
          <the-typography
            type="bodyMediumLong"
            v-html="text"
          />
        </div>
      </div>
    </div>
  </div>
  <template v-if="$props.withOptions">
    <div
      v-bind="$attrs"
      class="cardSection cardSection__content"
      :class="{
        [`mode--${$props.mode}`]: true,
        ['mode--loading']: $props.product.loading,
      }"
    >
      <template v-if="$props.personId && $props.product">
        <template v-if="showOptions">
          <div class="product__options">
            <template v-if="hasProductAccident && hasProductAccidentToggle">
              <div class="product__criteria">
                <the-typography type="heading05">
                  <span v-text="optionTitles.accident.title" />
                  &nbsp;
                  <the-icon
                    v-tooltip="optionTitles.accident.tooltip"
                    art="light"
                    html-tooltip
                    name="circle-info"
                  />
                </the-typography>
                <product-accident
                  :option="productPricing"
                  @select="selectOption"
                />
              </div>
            </template>
            <template v-if="hasProductDuration">
              <div class="product__criteria">
                <the-typography type="heading05">
                  <span v-text="optionTitles.duration.title" />
                  &nbsp;
                  <the-icon
                    v-tooltip="optionTitles.duration.tooltip"
                    art="light"
                    html-tooltip
                    name="circle-info"
                  />
                </the-typography>
                <product-duration
                  :category-id="categoryId"
                  :options="$props.product.prices"
                  :product-id="$props.productId"
                  :source="$props.source"
                  @select="selectOption"
                />
              </div>
            </template>
            <template v-if="hasProductFranchise">
              <div class="product__criteria">
                <the-typography type="heading05">
                  <span v-text="optionTitles.franchise.title" />
                  &nbsp;
                  <the-icon
                    v-tooltip="optionTitles.franchise.tooltip"
                    art="light"
                    html-tooltip
                    name="circle-info"
                  />
                </the-typography>
                <product-franchise
                  inline
                  :forced-mobile="$props.mode === 'multi' ? true : $props.product.prices.length > 6"
                  :options="$props.product.prices"
                  :source="$props.source"
                  @select="selectOption"
                />
              </div>
            </template>
            <template v-if="hasVitalProductFranchise">
              <div>
                <product-vital-franchise
                  class="product__criteria"
                  :forced-mobile="$props.mode === 'multi' ? true : $props.product.prices.length > 6"
                  :options="$props.product.prices"
                  :source="$props.source"
                  @select="selectOption"
                >
                  <template #amValue>
                    <div>
                      <the-typography type="heading05">
                        <span v-text="optionTitles.amValue.title" />
                        &nbsp;
                        <the-icon
                          v-if="optionTitles.amValue.tooltip"
                          v-tooltip="optionTitles.amValue.tooltip"
                          art="light"
                          html-tooltip
                          name="circle-info"
                        />
                      </the-typography>
                    </div>
                  </template>
                  <template #gfValue>
                    <div>
                      <the-typography type="heading05">
                        <span v-text="optionTitles.gfValue.title" />
                        &nbsp;
                        <the-icon
                          v-if="optionTitles.gfValue.tooltip"
                          v-tooltip="optionTitles.gfValue.tooltip"
                          art="light"
                          html-tooltip
                          name="circle-info"
                        />
                      </the-typography>
                    </div>
                  </template>
                </product-vital-franchise>
              </div>
            </template>
          </div>
        </template>
      </template>
    </div>
  </template>
  <div
    v-bind="$attrs"
    class="cardSection cardSection__footer"
    :class="{
      [`mode--${$props.mode}`]: true,
      ['mode--anon']: !$props.personId,
      ['mode--loading']: $props.product.loading,
    }"
  >
    <template v-if="$props.personId && $props.product">
      <transfer-right
        :person-id="$props.personId"
        :product-id="$props.productId"
      />
      <template v-if="$props.product.riskAssessmentRequired === false">
        <the-message
          theme="dark"
          v-html="$t('screen.products.noRiskAssessmentRequired')"
        />
      </template>
      <template v-if="hasProductPricing">
        <div
          class="product__costs"
          :class="{ 'product__costs--reduced': $props.product.valid && reduction.price > 0 }"
        >
          <template v-if="$props.product.loading">
            <img :src="LoadingIcon" />
          </template>
          <the-typography
            v-else
            type="heading01"
          >
            <product-price
              class="product__price"
              :prefix="pricePrefix"
              :price="productPricing.price"
              :suffix="$t('product.currency')"
            />
          </the-typography>
          <div
            v-if="reduction.price > 0"
            class="product__reduction"
          >
            <the-typography type="caption">
              <div class="reduction__instead">
                {{ $t('product.insteadOf') }}
                <product-price :price="reduction.price" />
              </div>
              <div
                v-for="(r, index) in reduction.labels"
                :key="index"
                class="reduction__info"
                v-text="getReductionLabel(r, index)"
              />
            </the-typography>
          </div>
        </div>
      </template>
      <product-action
        :person-id="$props.personId"
        :primary="!$props.product.selected"
        :product="$props.product"
        :selected="$props.product.selected"
        @add="add"
        @remove="remove"
        @search="searchMedicalOffice"
      />
      <product-doctor
        v-if="$props.product.medicalOffice"
        :person-id="$props.personId"
        :product="$props.product"
        :source="$props.source"
        @search="searchMedicalOffice"
      />
    </template>
    <template v-else>
      <div class="action">
        <Form.Button
          primary
          :text="$t('screen.priceWidget.widget.title')"
          @click="emit('open')"
        />
      </div>
    </template>
  </div>
  <the-form-modal
    v-model="isReplaceViewOpen"
    locked
    name="replaceModal"
  >
    <product-replace-modal
      :category-id="categoryId"
      :from-product-id="fromProductId"
      :group-id="groupId"
      :person-id="$props.personId"
      :source="$props.source"
      :to-product-id="$props.product.productId"
      @close="closeReplaceView"
      @replace="_product => replace(_product)"
    />
  </the-form-modal>
</template>

<script setup>
import { computed, ref } from 'vue'

import { events$, loading$ } from '@/services'
import { ReactivityUtil } from '@/utils/Reactivity'

import basketStore from '@/store/basket'
import useI18n from '@/hooks/useI18n'
import usePerson from '@/hooks/usePerson'
import usePersonSpecificProduct from '@/hooks/usePersonSpecificProduct'
import useProduct from '@/hooks/useProduct'
import useProductPersons from '@/hooks/useProductPersons'
import useToast from '@/hooks/useToast'

import * as Form from '@/components/Form'
import ProductAccident from '@/components/Product/ProductAccident'
import ProductAction from '@/components/Product/ProductAction'
import ProductDoctor from '@/components/Product/ProductDoctor'
import ProductDuration from '@/components/Product/ProductDuration'
import ProductFranchise from '@/components/Product/ProductFranchise'
import ProductPrice from '@/components/Product/ProductPrice'
import ProductReplaceModal from '@/components/Product/Replace/ReplaceModal'
import ProductVitalFranchise from '@/components/Product/ProductVitalFranchise'
import TransferRight from '@/components/HaX/TransferRight'

import LoadingIcon from '@/assets/loading.svg'
import { CATEGORY_TYPE, NOTIFICATION } from '@/config/constants'
import { EVENT_MEDICAL_OFFICE_MODAL_OPEN, EVENT_PRODUCT } from '@/config/events'

// HOOKS
const { addProductToOvpBasket } = useProductPersons()
const { t, te } = useI18n()
const { getPerson } = usePerson()
const { getProductPrice, removeProductFromPerson, selectProductOption, selectProductOptionForCategory } =
  usePersonSpecificProduct()
const {
  getCategoryIdFromProduct,
  getGroupIdFromProduct,
  getOptionTitles,
  hasAccidentToggle,
  hasDuration,
  hasFranchise,
  isUpgrade,
} = useProduct()
const { addToast } = useToast()

// INIT
const emit = defineEmits(['added', 'open', 'removed', 'update'])
const props = defineProps({
  mode: {
    type: String,
    default: 'single',
  },
  personId: {
    type: String,
    default: null,
  },
  product: {
    type: Object,
    required: true,
  },
  productId: {
    type: String,
    required: true,
  },
  source: {
    type: String,
    required: true,
  },
  withOptions: {
    type: Boolean,
    default: false,
  },
})

// DATA
const fromProductId = ref(null)
const isReplaceViewOpen = ref(false)

// COMPUTED
const categoryId = computed(() => {
  return getCategoryIdFromProduct(props.productId)
})

const groupId = computed(() => {
  return getGroupIdFromProduct(props.productId)
})

const hasProductAccident = computed(() => {
  return optionTitles.value.accident
})

const hasProductAccidentToggle = computed(() => {
  const person = getPerson(props.personId)
  return hasAccidentToggle({
    categoryId: categoryId.value,
    dateOfBirth: person.personData.dateOfBirth,
    kvgContractStartDate: person.kvgContractStartDate,
    product: product.value,
  })
})

const hasProductDuration = computed(() => {
  return hasDuration(product.value) && optionTitles.value.duration
})

const hasProductFranchise = computed(() => {
  return hasFranchise(product.value) && optionTitles.value.franchise
})

const hasProductPricing = computed(() => {
  return props.personId && !!product.value?.prices
})

const hasVitalProductFranchise = computed(() => {
  const { hasVitalFranchise } = useProduct()
  return hasVitalFranchise(product.value)
})

const optionTitles = computed(() => {
  return getOptionTitles(props.productId, groupId.value, categoryId.value)
})

/**
 * Linter thinks, that props.product is the same as product.value...
 */
// eslint-disable-next-line vue/no-dupe-keys
const product = computed(() => {
  return props.product
})

const productPricing = computed(() => {
  return getProductPrice(props.personId, props.productId) || { price: 0 }
})

const pricePrefix = computed(() => {
  return product.value?.showDiscountGroup && !product.value.selected ? t('product.asof') : ''
})

const reduction = computed(() => {
  const amount = Math.abs(productPricing.value.reduction)
  return {
    price: amount > 0 ? productPricing.value.price + amount : 0,
    labels: productPricing.value?.reductions ?? [],
  }
})

const showOptions = computed(() => {
  return (
    (product.value.valid || product.value.selected) &&
    ((hasProductAccident.value && hasProductAccidentToggle.value) ||
      hasProductDuration.value ||
      hasProductFranchise.value ||
      hasVitalProductFranchise.value)
  )
})

// METHODS
async function add(__options) {
  if (__options.replace && !__options.forceAdd) {
    openReplaceView(__options.productId)
  } else {
    loading$.start()
    const mode = isUpgrade(props.productId) ? 'ADD' : 'REPLACE'

    try {
      let __basket = await addProductToOvpBasket(props.personId, props.productId, { mode })
      __basket = await basketStore.updateOvpBasket(__basket)

      events$.emit(EVENT_PRODUCT.ADDED, {
        basket: __basket,
        categoryId: categoryId.value,
        personId: props.personId,
        productId: product.value.productId,
        source: props.source,
      })

      emit('added', props.productId)
      loading$.end()
    } catch (error) {
      loading$.failed()
      addToast({
        type: NOTIFICATION.ERROR,
        text: error?.messages[0],
      })
    }
  }
}

function closeReplaceView() {
  isReplaceViewOpen.value = false
  fromProductId.value = null
}

function getReductionLabel(__reduction, i) {
  const type = t(`info.${__reduction.type}`)
  const amount = Math.abs(__reduction.amount).toFixed(2)
  const currency = t('product.currency')
  const separator = reduction.value.labels.length - 1 !== i ? ';' : ''

  return `${type} ${amount} ${currency}${separator}`
}

function openReplaceView(productId) {
  isReplaceViewOpen.value = true
  fromProductId.value = productId
}

async function remove() {
  loading$.start()

  try {
    const __basket = await removeProductFromPerson(props.personId, props.productId)

    events$.emit(EVENT_PRODUCT.REMOVED, {
      basket: __basket,
      categoryId: categoryId.value,
      personId: props.personId,
      productId: product.value.productId,
      source: props.source,
    })

    emit('removed', props.productId)
    loading$.end()
  } catch (error) {
    loading$.failed()
    addToast({
      type: NOTIFICATION.ERROR,
      text: error?.messages[0],
    })
  }
}

async function replace(__product) {
  let _basket = ReactivityUtil.clone(basketStore.basket)
  const pBasket = _basket.persons.find(p => p.personId === props.personId)

  pBasket.products.products[fromProductId.value].selected = false
  pBasket.products.products[props.productId] = __product

  loading$.start({ blocking: false })

  _basket = await basketStore.updateOvpBasket(_basket)

  events$.emit(EVENT_PRODUCT.REMOVED, {
    basket: _basket,
    categoryId: props.categoryId,
    personId: props.personId,
    productId: fromProductId.value,
    source: props.source,
  })

  events$.emit(EVENT_PRODUCT.ADDED, {
    basket: _basket,
    categoryId: props.categoryId,
    personId: props.personId,
    productId: props.productId,
    source: props.source,
  })

  closeReplaceView()
  loading$.end()
}

function searchMedicalOffice() {
  events$.emit(EVENT_MEDICAL_OFFICE_MODAL_OPEN, {
    source: props.source,
    personId: props.personId,
    product: product.value,
  })
}

async function selectOption(option, simulate = false) {
  loading$.start()

  if (!product.value.selected) {
    simulate = true
  }

  try {
    let _basket
    if (categoryId.value === CATEGORY_TYPE.KVG) {
      _basket = await selectProductOptionForCategory(props.personId, categoryId.value, option)
    } else {
      _basket = await selectProductOption({
        personId: props.personId,
        productId: props.productId,
        option,
      })
    }

    _basket = await basketStore.updateOvpBasket(_basket, { simulate })
    if (simulate) {
      basketStore.persistBasket(_basket)
    }

    events$.emit(EVENT_PRODUCT.CHANGED, {
      basket: _basket,
      categoryId: categoryId.value,
      option,
      personId: props.personId,
      productId: props.productId,
      source: props.source,
    })

    emit('update', props.productId)
    loading$.end()

    return _basket
  } catch {
    loading$.failed()
  }
}
</script>

<style name="mobile" scoped>
.cardSection {
  display: flex;
  flex-direction: column;

  gap: 16px;
  padding: 16px;

  background-color: var(--c-primary-neutral-3);
}

.cardSection__head {
  .product__bullets {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 8px 0 16px 0;

    .bullet {
      display: flex;
      gap: 8px;

      .icon {
        padding: 2px 0 0 0;
        font-size: 16px;
      }
    }
  }
}

.cardSection__content {
  display: flex;
  justify-content: flex-end;

  .product__options {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .product__criteria,
  :deep(.product__criteria) {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

.cardSection__footer {
  position: relative;
  display: grid;
  grid-template-areas: 'price' 'action';

  .product__costs {
    grid-area: price;
  }

  .action {
    grid-area: action;
    margin: 5px 0;
  }
}

.mode--loading {
  & > *:not(.product__costs) {
    opacity: 0.2;
    pointer-events: none;
  }

  img {
    position: absolute;
    height: 45px;
    width: 45px;
    left: calc(50% - 22.5px);
  }
}
</style>

<style name="desktop" scoped>
@media (--md) {
  .cardSection {
    padding: 24px 16px;
  }

  .cardSection__footer {
    &.mode--single {
      grid-template-areas: 'price action';

      .action {
        text-align: right;

        &:deep(.button) {
          width: 278px !important;
        }
      }
    }

    &.mode--multi {
      grid-template-rows: 1fr 1fr;
    }

    &.mode--anon {
      grid-template-rows: min-content 1fr;
    }
  }
}
</style>
