<template>
  <!-- prettier-ignore -->
  <div
    class="ovp-basket-widget ovp-a11y-container"
    :app-language="selectedLanguage"
    :app-name="appName$"
    :app-version="version$"
  >
    <basket-container :options="{ configuratorUrl, source: $props.source }" />
  </div>
</template>

<script setup>
import { computed, inject } from 'vue'

import useApplication from '@/hooks/useApplication'
import useI18n from '@/hooks/useI18n'

import BasketContainer from '@/components/BasketWidget/BasketContainer'

import { SOURCE } from '@/config/events'

// HOOKS
const { getDvpUrl } = useApplication()
const { selectedLanguage } = useI18n()

// INIT
const appName$ = inject('appName$')
const version$ = inject('version$')
defineProps({
  source: {
    type: String,
    default: SOURCE.BASKET_WIDGET,
  },
})

// COMPUTED
const configuratorUrl = computed(() => {
  return getDvpUrl(selectedLanguage.value)
})
</script>

<style name="global" scoped>
* {
  & :deep(button) {
    font-weight: bold;
  }

  & :deep(button),
  input,
  select {
    font-family:
      Open Sans,
      sans-serif !important;
  }
}
</style>
