import { required } from '@vuelidate/validators'

import { ValidatorsUtil } from '@/utils/Validators'
import { dateOfBirth } from '@/validations/components'

export default {
  location: {
    required,
    location: ValidatorsUtil.isLocation,
  },
  dateOfBirth,
  gender: {
    required,
  },
}
