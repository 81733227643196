<template>
  <!-- prettier-ignore -->
  <div
    id="entryWidget"
    class="entry"
    :class="classes"
    :app-language="selectedLanguage"
    :app-name="appName$"
    :app-version="version$"
  >
    <widget-loading
      v-if="isLoading || !isReadyWithPartner"
      class="entry-loading"
    />
    <div
      v-else
      class="entry__wrapper"
    >
      <div class="entry__content">
        <div
          v-if="computedTitle"
          class="entry__title"
          @click="navigate"
          v-text="computedTitle"
        />
        <div
          v-if="$props.tiny"
          class="entry__button"
        >
          <Form.Button
            primary
            type="button"
            :text="button"
            @click="navigate"
          />
        </div>
        <div v-else>
          <entry-form
            v-model="formData"
            :basket="basketStore.basket"
            :button-text="button"
            :channel="CHANNEL.ENTRYWIDGET"
            :show-restart="hasBasket"
            :theme="$props.theme"
            @navigate="navigate"
            @submit="createBasket"
          />
        </div>
        <login-action
          v-if="isLoginEnabled && !$props.tiny"
          ref="loginRef"
          hide-notification
          show-icon
          :channel="CHANNEL.ENTRYWIDGET"
          :submit-callback="navigate"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, inject, ref } from 'vue'

import { v4 as uuidv4 } from 'uuid'

import { events$ } from '@/services'

import basketStore from '@/store/basket'
import partnerStore from '@/store/partner'
import useApplication from '@/hooks/useApplication'
import useI18n from '@/hooks/useI18n'
import useToast from '@/hooks/useToast'
import useABConfig from '@/hooks/useABConfig'

import * as Form from '@/components/Form'
import EntryForm from '@/modules/EntryWidget/components/EntryForm'
import WidgetLoading from '@/components/Container/WidgetLoading'
import { LoginAction } from '@/components/Login'

import { CHANNEL, DEFAULT_MASTER_TREATY, NOTIFICATION } from '@/config/constants'
import { EVENT_TRACKING, SOURCE } from '@/config/events'

// HOOKS
const { getDvpUrl, isReadyWithPartner } = useApplication()
const { selectedLanguage, t } = useI18n()
const { addToast } = useToast()
const { abConfig } = useABConfig()

// INIT
const appName$ = inject('appName$')
const version$ = inject('version$')
const props = defineProps({
  buttonText: {
    type: String,
    default: '',
  },
  header: {
    type: Boolean,
    default: false,
  },
  theme: {
    type: String,
    default: 'dark',
  },
  titleLabel: {
    type: String,
    default: '',
  },
  tiny: {
    type: Boolean,
    default: false,
  },
})

// DATA
const isLoading = ref(false)
const loginRef = ref(null)

// COMPUTED
const button = computed(() => {
  if (hasBasket.value) {
    return t('entry.resume')
  } else {
    return props.buttonText ? props.buttonText : t('entry.button')
  }
})

const classes = computed(() => {
  return {
    [`entry--${props.theme}`]: true,
    'entry--tiny': props.tiny,
    header: props.header,
  }
})

const computedTitle = computed(() => {
  if (hasBasket.value) {
    return t('entry.return')
  } else {
    return props.titleLabel
  }
})

const formData = computed(() => {
  const person = basketStore.basket.persons?.[0] ?? false

  if (!person) return {}

  const personData = person.personData
  return {
    dateOfBirth: personData.dateOfBirth,
    gender: personData.gender,
    location: basketStore.basket.address,
    personId: person.personId,
  }
})

const hasBasket = computed(() => {
  return basketStore.basket.basketId && basketStore.basket.persons.length > 0
})

const isLoginEnabled = computed(() => {
  return (
    !!loginRef.value?.isLoginModalOpen ||
    (!partnerStore.isPartner.value && !partnerStore.isCollective.value && !basketStore.basket.existingCustomer)
  )
})

// METHODS
function createBasket(data) {
  const shouldResume = !!data.personId
  isLoading.value = true

  if (shouldResume) {
    navigate()
  } else {
    const personId = data.personId ?? uuidv4()
    const payload = {
      address: data.location,
      channel: CHANNEL.ENTRYWIDGET,
      language: selectedLanguage.value.toUpperCase(),
      masterTreaty: DEFAULT_MASTER_TREATY,
      persons: [
        {
          contractModified: true,
          personId,
          immigrant: false,
          personData: {
            dateOfBirth: data.dateOfBirth,
            gender: data.gender,
          },
        },
      ],
    }

    return basketStore
      .updateOvpBasket(payload)
      .then(() => {
        events$.emit(EVENT_TRACKING.ENTERED, { source: CHANNEL.ENTRYWIDGET })
        navigate()
      })
      .catch(error => {
        if (error?.source !== SOURCE.BACKEND) {
          addToast({
            text: error,
            type: NOTIFICATION.ERROR,
          })
        }
      })
  }
}

function navigate() {
  const baseUrl = getDvpUrl(selectedLanguage.value)

  let target = baseUrl
  if (abConfig.value.entryWidgetTarget) {
    target += '#' + abConfig.value.entryWidgetTarget
  }

  window.open(target, '_self').focus()
}
</script>

<style name="variables">
.entry {
  --sm-body-padding: 10px;
  --md-body-padding: 20px;
  --lg-body-padding: 30px;
}
</style>

<style name="global" scoped>
* {
  & :deep(button) {
    font-weight: bold;
  }

  & :deep(button),
  input,
  select {
    font-family:
      Open Sans,
      sans-serif !important;
  }
}
</style>

<style name="mobile" scoped>
.entry {
  & :deep(*) {
    font-family:
      Open Sans,
      sans-serif;
  }

  & :deep(.prompt) {
    margin: 11px 0 16px;
  }

  position: relative;
  padding: 20px 0;
  margin-left: calc(-1 * var(--sm-body-padding));
  margin-right: calc(-1 * var(--sm-body-padding));

  background-color: var(--c-primary-color-1);
  color: var(--c-primary-neutral-3);

  &__wrapper {
    margin: 0 15px;
  }

  &__title {
    font-weight: bold;
    font-size: 20px;
    line-height: 20px;
    margin: 15px 0 5px 0;
    cursor: pointer;
  }

  &__grid {
    display: flex;
    flex-wrap: wrap;
    margin: -25px;
    padding: 20px 15px;
  }

  &__cell {
    width: 50%;
    max-width: 50%;
    padding: 15px 10px;

    &--large {
      width: 100%;
      max-width: 100%;
    }
  }

  &--tiny.entry {
    .entry {
      &__title {
        font-size: 20px;
        line-height: 25px;
      }

      &__button {
        padding: 15px 0;
      }
    }
  }

  &--light {
    color: var(--c-primary-neutral-1);
    background-color: var(--c-primary-neutral-3);

    .entry {
      &__field {
        background: inherit;
      }

      &__skeleton {
        height: 55px;
        border-bottom: 1px solid var(--c-secondary-neutral-2);
      }
    }
  }
}
</style>

<style name="tablet" scoped>
/* Special handling for tablet on .com */
@media (--sm) {
  .entry {
    &--tiny.entry {
      .entry {
        &__title {
          margin: auto 0;
        }

        &__button {
          width: 300px;
          margin: 0 0 0 auto;
          padding-bottom: 0;

          .button {
            width: 100%;
            min-width: 300px;
          }
        }
      }
    }
  }
}
</style>

<style name="desktop" scoped>
@media (--md) {
  .entry :deep(.prompt) {
    margin: 6px 0 16px;
  }

  .entry {
    &--tiny.entry {
      padding: 20px 0;

      .entry {
        &__content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 50px;

          &:after {
            content: '';
            min-height: inherit;
            font-size: 0;
          }
        }

        &__title {
          flex: 1 0 50%;
          margin: auto 0;
        }

        &__button {
          flex: 0 0 300px;
          margin: auto 0 auto 20px;
          padding: 0;
        }
      }
    }

    &-buttons {
      display: flex;
      flex-direction: row;
      padding: 0 20px;

      button {
        flex: 1 1 50%;

        &:first-child {
          margin-right: 10px;
        }

        &:last-child {
          margin-left: 10px;
        }
      }
    }
  }
}
</style>

<style name="desktop-com-handling" scoped>
/* Special handling for desktop on .com */
/* you need 1000px for a clean cut between full form size */
@media (min-width: 1000px) {
  .entry {
    padding: 35px 0 20px;

    &__wrapper {
      width: 100%;
      max-width: 1000px;
      margin: 0 auto;
    }

    &__content {
      max-width: 940px;
      margin: 0 30px;
    }

    &__title {
      font-size: 20px;
      line-height: 25px;
      margin-top: 0;
      margin-bottom: 10px;
    }

    &__button {
      width: auto;
      padding-top: 0;

      .button {
        width: 100%;
        min-width: 300px;
      }
    }
  }
}
</style>

<style name="header-hero-handling">
/* Special handling for tablet devices for .header */
.entry.header {
  margin-left: calc(-1 * var(--sm-body-padding));
  margin-right: calc(-1 * var(--sm-body-padding));

  @media (--md) {
    margin-left: calc(-1 * var(--md-body-padding));
    margin-right: calc(-1 * var(--md-body-padding));
  }

  @media (min-width: 1025px) {
    margin-left: calc(-1 * var(--lg-body-padding));
    margin-right: calc(-1 * var(--lg-body-padding));
  }
}
</style>
