<template>
  <div
    class="ovp-a11y-container"
    :app-language="selectedLanguage"
    :app-name="appName$"
    :app-version="version$"
  >
    <screen-auth />
    <the-confirmation />
    <the-loading />
    <div id="modals"></div>
    <notifications />
    <medical-office-search />
    <login-modal />
    <toasts />
  </div>
</template>

<script setup>
import { inject, onBeforeMount, onMounted, watch } from 'vue'

import { events$ } from '@/services'

import basketStore from '@/store/basket'
import partnerStore from '@/store/partner'
import useApplication from '@/hooks/useApplication'
import useBasket from '@/hooks/useBasket'
import useI18n from '@/hooks/useI18n'
import useSession from '@/hooks/useSession'
import useSurvey from '@/hooks/useSurvey'
import useSync from '@/hooks/useSync'
import useUrl from '@/hooks/useUrl'

import MedicalOfficeSearch from '@/components/MedicalSearch/MedicalOfficeSearch'
import Notifications from '@/components/Container/Notifications'
import ScreenAuth from '@/components/Screen/ScreenAuth'
import TheConfirmation from '@/components/Modal/Confirmation'
import TheLoading from '@/components/Container/Loading'
import Toasts from '@/components/Container/Toasts'
import { LoginModal } from '@/components/Login'

import { registerUpdateBasketListeners } from '@/helpers/GlobalEventHandler'

import { EVENT_DOTCOM_SURVEY_WEBSITE } from '@/config/events'
import { DEFAULT_DISTRIBUTION_PARTNER_NUMBER } from '@/config/constants'

// HOOKS
const { initializeClearSequence, isReadyWithPartner } = useApplication()
const { updateLanguage } = useBasket()
const { browserLanguage, selectedLanguage } = useI18n()
const { autostart } = useSession()
const { triggerSurvey } = useSurvey()
const { registerSync } = useSync()
const { react } = useUrl()

// INIT
const appName$ = inject('appName$')
const version$ = inject('version$')

// METHODS
function emitEventWhenReady() {
  return new Promise(function (resolve) {
    if (isReadyWithPartner.value) resolve()

    const unwatch = watch(
      () => isReadyWithPartner.value,
      () => {
        unwatch()
        resolve()
      }
    )
  })
}

// LIFECYCLE HOOKS
onBeforeMount(async () => {
  initializeClearSequence()
  const __basket = await autostart()

  if (basketStore.isTemporaryBasket()) {
    await partnerStore.install({
      contractStartDate: __basket.contractStartDate,
      language: __basket.language,
      collectiveNumber: null,
      partnerNumber: null,
      distributionPartnerNumber: DEFAULT_DISTRIBUTION_PARTNER_NUMBER,
    })
  }

  if (basketStore.basket.language.toLowerCase() !== browserLanguage) {
    await updateLanguage({ lang: browserLanguage })
  }

  events$.once(EVENT_DOTCOM_SURVEY_WEBSITE, () => {
    triggerSurvey()
  })
})

onMounted(() => {
  registerUpdateBasketListeners()
  emitEventWhenReady().then(() => {
    // tab-sync needs to be registered, after all the BE-/localStorage operations are done
    registerSync()

    // react to URL-params (e.g. open login)
    react()
  })
})
</script>
